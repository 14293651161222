import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this); //this breaks the whole app when untoggled? lol
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    toggleDropdown(e) {
        if (window.innerWidth < 992) { // Adjust the width as per your mobile breakpoint
            e.preventDefault();
            const parentLi = e.currentTarget.parentNode;
            parentLi.querySelector('.submenu').classList.toggle("active");
            parentLi.classList.toggle("open");
        }
    }

    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo = 'light', color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Redemption Psychiatry Logo" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Redemption Psychiatry Logo" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Redemption Psychiatry Logo" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Redemption Psychiatry Logo" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Redemption Psychiatry Logo" />;
        }

        return (
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown">
                                    <Link to="/about" onClick={this.toggleDropdown}>About Us</Link>
                                    <ul className="submenu">
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/provider-biographies">Our Team</Link></li>
                                        <li><Link to="/videos">Videos</Link></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://recruiting.paylocity.com/recruiting/jobs/All/954f045d-735f-4e04-8ec7-216c4991e050/Redemption-Psychiatry">Career Opportunities</a></li>
                                    </ul>
                                </li>
                                <li><Link to="/services">Services</Link></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://rpsychtms.com">TMS</a></li>
                                <li><Link to="/ketamine">Ketamine</Link></li>
                                <li className="has-droupdown">
                                    <Link to="/currentpatients" onClick={this.toggleDropdown}>Resources</Link>
                                    <ul className="submenu">
                                        <li><Link to="/pamphlets">Pamphlets</Link></li>
                                        <li><Link to="/currentpatients">Patient Resources</Link></li>
                                        <li><Link to="/partners">Our Partners</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/newpatients">New Patients</Link></li>
                                <li><Link to="/faq" >FAQs</Link></li>
                                <li><Link to="/contact" >Contact Us/Locations</Link></li>
                            </ul>
                        </nav>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;