// MarketingRedirect.jsx
import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import base64url from 'base64url'; // Ensure installed via npm: npm install base64url

function MarketingRedirect() {
  const { uniqueCode } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [done, setDone] = useState(false); // Track if the redirect is completed

  useEffect(() => {
    try {
      const parts = uniqueCode.split('-');
      if (parts.length < 3) {
        throw new Error("Invalid unique code format.");
      }
  
      const encodedUrl = parts.slice(2).join('-');
      let decodedUrl = base64url.decode(encodedUrl);
  
      // Automatically prepend 'https://' if no scheme is present
      if (!decodedUrl.match(/^[a-zA-Z]+:\/\//)) {
        decodedUrl = `https://${decodedUrl}`;
      }
  
      const isValidUrl = /^(https?:\/\/)[^\s$.?#].[^\s]*$/gm.test(decodedUrl);
      if (!isValidUrl) {
        throw new Error("Invalid redirect URL.");
      }
  
      if (window.gtag) {
        window.gtag("event", "MarketingMaterialClick", {
          event_category: "MarketingMaterial",
          event_label: `${parts[0]}-${parts[1]}`,
          redirect_destination: decodedUrl,
        });
      }
  
      setTimeout(() => {
        window.location.href = decodedUrl;
        setDone(true);
      }, 150);
    } catch (err) {
      console.error("Error processing uniqueCode:", err);
      setNotFound(true);
    }
  }, [uniqueCode]);
  

  // If the code doesn't exist or there's an error, redirect to homepage
  if (notFound) {
    return <Redirect to="/" />;
  }

  // If redirect is done
  if (done) {
    return null;
  }

  // Render a quick "loading" or "redirecting" message
  return <div>Redirecting...</div>;
}

export default MarketingRedirect;