import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";


class CorporateBusiness extends Component {
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Homepage" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" style={{ marginBottom: '50px' }} />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--20 pb--150"></div>
                {/* End Breadcrump Area */}
                {/* Start Main Content  */}
                <div className="main-content bg_color--1">
                    <div className="inner">
                        <iframe src='https://simplebooklet.com/redemptionpamphlets'
                            width='100%'
                            height="1000px"
                            style={{ border: '0px' }}
                            title="Redemption Pamphlets"></iframe>
                    </div>
                </div>
                {/* End Main Content  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;