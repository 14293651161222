import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// React Required
import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout 
// import DarkMainDemo from './dark/MainDemo';
// import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Team from "./elements/Team";
import Ketamine from "./elements/Ketamine";
import RevolutionMH from "./elements/RevolutionClinic";
import ProfessionalResources from "./elements/ProfessioanlResources";
import Partners from './elements/Partners';
import FAQ from "./elements/FAQ";
import Services from "./elements/Services";
import Videos from "./elements/Videos";
import Contact from "./elements/Contact";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import CrisisLines from "./elements/CrisisLines";
import PrivacyPolicy from "./elements/PrivacyPolicy"
import FormAccepted from "./elements/FormAccepted";
import Pamphlets from "./elements/Pamphlets";
import error404 from "./elements/error404";
// import GoogleAdsLanding from "./elements/GoogleAdsLanding";


// Blocks Layout

import About from "./blocks/About";
import Counters from "./blocks/Counters";
import NewPatientsValant from "./elements/NewPatientsValant";
import CurrentPatients from "./elements/CurrentPatients";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import MarketingRedirect from './blocks/MarketingRedirect';
import AllYouNeedToKnowAboutKetamine from "./blocks/blog-posts/AllYouNeedToKnowAboutKetamine";
import KetamineTreatmentTheFutureOfDepressionTreatment from "./blocks/blog-posts/KetamineTreatmentTheFutureOfDepressionTreatment";
import BattlingTheStigmaAroundMentalHealthTreatment from "./blocks/blog-posts/BattlingTheStigmaAroundMentalHealthTreatment";
import LookingAfterYourMentalHealthDuringThePandemic from "./blocks/blog-posts/LookingAfterYourMentalHealthDuringThePandemic";

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const sendAnalyticsEvent = (eventName) => {
    if (window.gtag) {
        window.gtag('event', eventName, {
            event_category: 'Page Visit',
            event_label: 'New Patients Routing',
            value: 1,
        });
    }
};

const NewPatientsRoute = () => {
    useEffect(() => {
        sendAnalyticsEvent('Viewed NewPatientsValant');
    }, []);

    return <NewPatientsValant />;
};



class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness} />


                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
                    <Route exact path={`${process.env.PUBLIC_URL}/provider-biographies`} component={Team} />
                    <Route exact path={`${process.env.PUBLIC_URL}/ketamine`} component={Ketamine} />
                    <Route exact path={`${process.env.PUBLIC_URL}/revolutionmh`} component={RevolutionMH} />
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                    <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services} />
                    <Route exact path={`${process.env.PUBLIC_URL}/partners`} component={Partners} />
                    <Route exact path={`${process.env.PUBLIC_URL}/videos`} component={Videos} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />
                    <Route exact path={`${process.env.PUBLIC_URL}/privacypolicy`} component={PrivacyPolicy} />
                    <Route exact path={`${process.env.PUBLIC_URL}/formaccepted`} component={FormAccepted} />
                    <Route exact path={`${process.env.PUBLIC_URL}/crisislines`} component={CrisisLines} />
                    <Route exact path={`${process.env.PUBLIC_URL}/professionalresources`} component={ProfessionalResources} />
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/landingpage`} component={GoogleAdsLanding}/> */}


                    {/* Blocks Elements  */}
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters} />
                    <Route exact path={`${process.env.PUBLIC_URL}/newpatients`} component={NewPatientsRoute} />
                    <Route exact path={`${process.env.PUBLIC_URL}/currentpatients`} component={CurrentPatients} />
                    <Route exact path={`${process.env.PUBLIC_URL}/pamphlets`} component={Pamphlets} />
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup} />
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery} />
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand} />
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar} />
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm} />
                    <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap} />
                    <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns} />
                    <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable} />
                    <Route exact path={`${process.env.PUBLIC_URL}/AllYouNeedToKnowAboutKetamine`} component={AllYouNeedToKnowAboutKetamine} />
                    <Route exact path={`${process.env.PUBLIC_URL}/KetamineTreatmentTheFutureOfDepressionTreatment`} component={KetamineTreatmentTheFutureOfDepressionTreatment} />
                    <Route exact path={`${process.env.PUBLIC_URL}/BattlingTheStigmaAroundMentalHealthTreatment`} component={BattlingTheStigmaAroundMentalHealthTreatment} />
                    <Route exact path={`${process.env.PUBLIC_URL}/LookingAfterYourMentalHealthDuringThePandemic`} component={LookingAfterYourMentalHealthDuringThePandemic} />

                    {/* Marketing Redirect Routes */}
                    <Route path={`${process.env.PUBLIC_URL}/marketingmaterials/:uniqueCode`} component={MarketingRedirect} />


                    {/* 404 Fallback */}
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                    <Route component={error404} />

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();